
import {
  Component, Vue,
} from 'vue-property-decorator';
import { MUTATIONS } from '@/store/namespaces';
import { Mutation } from 'vuex-class';
import _ from 'lodash';

@Component
export default class Confirm extends Vue {
    title = '';

    content = '';

    cookieSkipKey = '';

    skip = false;

    dialog = false;

    @Mutation(MUTATIONS.FINISH_CONFIRM)
    finishConfirm!: (result: boolean) => void

    onAccept(e: KeyboardEvent | null) {
      if (this.cookieSkipKey && this.skip) {
        this.$cookies.set(this.cookieSkipKey, true);
      }

      if (_.isNil(e) || e.key === 'Enter') {
        this.dialog = false;
        this.skip = false;
        this.finishConfirm(true);
      }
    }

    onDeny() {
      this.dialog = false;
      this.skip = false;
      this.finishConfirm(false);
    }

    created() {
      window.addEventListener('keyup', this.onAccept);

      this.$store.subscribe((mutation, state) => {
        if (mutation.type === MUTATIONS.SHOW_CONFIRM) {
          this.title = state.confirm.title;
          this.content = state.confirm.content;
          this.cookieSkipKey = state.confirm.cookieSkipKey;
          this.dialog = state.confirm.isOpen;

          this.$nextTick(() => {
            if (this.cookieSkipKey) {
              if (this.$cookies.isKey(this.cookieSkipKey)) {
                const skip = this.$cookies.get(this.cookieSkipKey);
                if (skip) {
                  this.onAccept(null);
                } else {
                  this.dialog = true;
                }
              } else {
                this.dialog = true;
              }
            } else {
              this.dialog = true;
            }
          });
        }
      });
    }

    beforeDestroy() {
      window.removeEventListener('keyup', this.onAccept);
    }
}
