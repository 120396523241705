
import { Component, Vue } from 'vue-property-decorator';
import CustomSnackbar from '@/components/plugins/CustomSnackbar.vue';
import LoadingOverlay from '@/components/plugins/LoadingOverlay.vue';
import Confirm from '@/components/plugins/Confirm.vue';

@Component({
  components: {
    Confirm,
    LoadingOverlay,
    CustomSnackbar,
  },
})
export default class App extends Vue {
  oneTimeResetColumnsKey = 'columns_reset';

  oneTimeResetColumnsValue = 'b4Ka2RBI6dy0e11APS4c';

  languageKey = 'language';

  mounted() {
    const columnsReset = localStorage.getItem(this.oneTimeResetColumnsKey);
    if (columnsReset !== this.oneTimeResetColumnsValue) {
      localStorage.removeItem('vehicle_log_sort_by');
      localStorage.setItem(this.oneTimeResetColumnsKey, this.oneTimeResetColumnsValue);
    }

    const language = localStorage.getItem(this.languageKey);
    if (!language) {
      localStorage.setItem(this.languageKey, 'Polish');
    } else {
      switch (language) {
        case 'English': this.$i18n.locale = 'en'; break;
        case 'Ukrainian': this.$i18n.locale = 'ua'; break;
        default: this.$i18n.locale = 'pl'; break;
      }
    }
  }
}
