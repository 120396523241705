import VueI18n from 'vue-i18n';
import en from '@/i18n/en.json';
import pl from '@/i18n/pl.json';
import ua from '@/i18n/ua.json';
import Vue from 'vue';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  messages: {
    en,
    pl,
    ua,
  },
});
