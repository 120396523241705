/* tslint:disable */
/* eslint-disable */

import _Vue from 'vue';
import { MUTATIONS } from '@/store/namespaces';
import store from '../store';
import { NotificationTypeEnum } from '@/enums/notification-type.enum'

class Notifier {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$notifier = {
      success: (content: string[]) => {
        store.commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Success, content });
      },
      error: (content: string[]) => {
        store.commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content });
      },
      info: (content: string[]) => {
        store.commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Info, content })
      }
    };
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $notifier: {
      success: (content: string[]) => void;
      error: (content: string[]) => void;
      info: (content: string[]) => void;
    };
  }
}

export default new Notifier();
