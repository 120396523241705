/* tslint:disable */
/* eslint-disable */

import axios from 'axios';
import router from '@/router';
import { TokenService } from '@/services/token.service';
import jwtDecode from 'jwt-decode';

const qs = require('qs');

export namespace ApiService {
  export function configureAxios() {
    if(process.env.NODE_ENV === 'development') {
      axios.defaults.baseURL = process.env.VUE_APP_EXPRESS_URL + '/api';
    } else {
      axios.defaults.baseURL = '/api';
    }
    axios.defaults.withCredentials = true;
  }

  export function get(resource: string, params: any) {
    return axios.get(resource, params);
  }

  export function post(resource: string, params: any) {
    return axios.post(resource, params);
  }

  export function put(resource: string, params: any) {
    return axios.put(resource, params);
  }

  export function del(resource: string, params: any) {
    return axios.delete(resource, params);
  }

  export function send(options: any) {
    return axios(options);
  }
}
