
import _Vue from 'vue';
import { MUTATIONS } from '@/store/namespaces';
import store from '../store';

class OnceDialog {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$onceDialog = (title: string, content: string): Promise<void> => {
      store.commit(MUTATIONS.SHOW_ONCE_DIALOG, { title, content });
      return new Promise<void>((resolve) => {
        store.subscribe((mutation, state) => {
          if (mutation.type === MUTATIONS.HIDE_ONCE_DIALOG) {
            resolve();
          }
        });
      });
    }
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $onceDialog: (title: string, content: string) => Promise<void>
  }
}

export default new OnceDialog();
