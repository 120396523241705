/* tslint:disable */
/* eslint-disable */

import _Vue from 'vue';
import { MUTATIONS } from '@/store/namespaces';
import store from '../store';

class Confirm {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$confirm = (title: string, content: string, cookieSkipKey?: string | undefined): Promise<boolean> => {
      store.commit(MUTATIONS.SHOW_CONFIRM, { title, content, cookieSkipKey });
      return new Promise<boolean>((resolve) => {
        store.subscribe((mutation, state) => {
          if (mutation.type === MUTATIONS.FINISH_CONFIRM && state.confirm.result !== null) {
            resolve(state.confirm.result);
          }
        });
      });
    }
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $confirm: (title: string, content: string, cookieSkipKey?: string | undefined) => Promise<boolean>
  }
}

export default new Confirm();
