import { MUTATIONS } from '@/store/namespaces';
import {
  ArticleGroup,
  RootState, ShowConfirm, ShowMessage, ShowOnceDialog, UserInfo,
} from '@/store/types';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<RootState> = {
  [MUTATIONS.SET_INITIALIZED](state: RootState, initialized: boolean) {
    state.initialized = initialized;
  },
  [MUTATIONS.SET_LOADING](state: RootState, loading: boolean) {
    state.loading = loading;
  },
  [MUTATIONS.SHOW_SNACKBAR_MESSAGE](state: RootState, payload: ShowMessage) {
    state.snackbar = {
      type: payload.type,
      content: payload.content,
    };
  },
  [MUTATIONS.SET_USER_INFO](state: RootState, payload: UserInfo) {
    state.userInfo = { ...payload };
  },
  [MUTATIONS.SHOW_CONFIRM](state: RootState, payload: ShowConfirm) {
    state.confirm = { ...payload, result: null, isOpen: true };
  },
  [MUTATIONS.FINISH_CONFIRM](state: RootState, payload: boolean) {
    state.confirm.result = payload;
    state.confirm.isOpen = false;
  },
  [MUTATIONS.SET_ARTICLE_GROUPS](state: RootState, groups: ArticleGroup[]) {
    state.articleGroups = groups;
  },
  [MUTATIONS.VISIT_VEHICLE_LOG](state: RootState, payload: boolean) {
    state.visitedHints.vehicleLog = payload;
  },
  [MUTATIONS.SHOW_ONCE_DIALOG](state: RootState, payload: ShowOnceDialog) {
    state.onceDialog = { ...payload, isOpen: true };
  },
  [MUTATIONS.HIDE_ONCE_DIALOG](state: RootState) {
    state.onceDialog.isOpen = false;
  }
};
