
import { Component, Vue } from 'vue-property-decorator';
import { MUTATIONS } from '@/store/namespaces';
import { NotificationTypeEnum } from '@/enums/notification-type.enum';

@Component
export default class CustomSnackbar extends Vue {
    type: NotificationTypeEnum = NotificationTypeEnum.Info;

    snackbarTexts: string[] = [];

    show = false;

    created() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === MUTATIONS.SHOW_SNACKBAR_MESSAGE) {
          this.snackbarTexts = state.snackbar.content;
          this.type = state.snackbar.type;
          this.show = false;
          this.$nextTick(() => {
            this.show = true;
          });
        }
      });
    }
}
