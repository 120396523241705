/* tslint:disable */
/* eslint-disable */

import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import router from '@/router';
import { TokenService } from '@/services/token.service';
import i18n from '@/i18n/config';
import { LF } from '@/i18n/LF'

const qs = require('qs');

export class AuthService {

  static async isLoggedIn(): Promise<boolean> {
    return await axios.get('/isloggedin')
      .then((result) => {
        return true;
      }).catch(async () => {
        return await axios.post('/refreshtoken').then(() => {
          return true;
        }).catch(() => false)
      });
  }

  static async isSignatureDevice(): Promise<boolean> {
    return await axios.get('/issignaturedevice')
      .then((result) => {
        return result.data;
      }).catch(() => false);
  }

  static async login(credentials: LoginCredentials) {

    const actionResult: LoginResult = {
      success: false,
      errors: [],
    };

    await axios.post('/login', { email: credentials.email, password: credentials.password })
      .then((result) => {
        if (result.data && !result.data.success) {
          actionResult.errors = result.data.errors;
        } else {
          actionResult.success = true;
          TokenService.isTokenSet(true);
        }
      })
      .catch(async (error) => {
          if (error.response?.status === 429) {
            actionResult.errors.push(i18n.t(LF.TooManyLoginAttempts) as string);
            actionResult.retryAfter = error.response.headers['retry-after'] as number | undefined;
          }
        actionResult.errors.push(i18n.t(LF.ServerIsTemporaryUnavailable) as string);
        if (router.currentRoute.path !== '/login') {
          await router.push('/login');
        }
        TokenService.removeIsTokenSet();
        return new axios.Cancel('Logout forced.');
      });

    return actionResult;
  }

  static async logout() {
    const actionResult: LoginResult = {
      success: false,
      errors: [],
    };

    await axios.get('/logout')
      .then(() => {
        actionResult.success = true;
      })
      .catch(() => {
        actionResult.errors.push(i18n.t(LF.SomethingWentWrong) as string);
      });

    return actionResult;
  }
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface LoginResult {
  retryAfter?: number;
  success: boolean;
  errors: string[];
}
