/* tslint:disable */
/* eslint-disable */

import _Vue from 'vue';
import Validators from '@/validators'

class ValidatorsPlugin {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  install(Vue: typeof _Vue, options?: any) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$validators = new Validators()
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $validators: Validators
  }
}

export default new ValidatorsPlugin();
