const IS_ACCESS_TOKEN_SET = 'is_access_token_set';
const IS_REFRESH_TOKEN_SET = 'is_refresh_token_set';

export namespace TokenService {
  export function getIsTokenSet() {
    return localStorage.getItem(IS_ACCESS_TOKEN_SET);
  }

  export function isTokenSet(state: boolean) {
    localStorage.setItem(IS_ACCESS_TOKEN_SET, state.toString());
    localStorage.setItem(IS_REFRESH_TOKEN_SET, state.toString());
  }

  export function removeIsTokenSet() {
    localStorage.removeItem(IS_ACCESS_TOKEN_SET);
    localStorage.removeItem(IS_REFRESH_TOKEN_SET);
  }

  export function getIsRefreshTokenSet() {
    return localStorage.getItem(IS_REFRESH_TOKEN_SET);
  }
}
