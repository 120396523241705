
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { MUTATIONS } from '@/store/namespaces';

@Component
export default class LoadingOverlay extends Vue {
    show = false;

    created() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === `${MUTATIONS.SET_LOADING}`) {
          this.show = state.loading;
        }
      });
    }
}
