/* tslint:disable */
/* eslint-disable */

import _Vue from 'vue';
import { MUTATIONS } from '@/store/namespaces';
import store from '../store';

class Loading {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  install(Vue: typeof _Vue, options?: any) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$loading = {
      start: () => {
        store.commit(`${MUTATIONS.SET_LOADING}`, true);
      },
      stop: () => {
        store.commit(`${MUTATIONS.SET_LOADING}`, false);
      },
    };
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $loading: {
      start: () => void;
      stop: () => void;
    };
  }
}

export default new Loading();
