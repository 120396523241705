/* tslint:disable */
/* eslint-disable */

import _Vue from 'vue';
import { MUTATIONS } from '@/store/namespaces';
import store from '../store';
import { LF } from '@/i18n/LF';

class LfPlugin {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  install(Vue: typeof _Vue, options?: any) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$LF = LF
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $LF: typeof LF
  }
}

export default new LfPlugin();
