import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { GETTERS } from '@/store/namespaces';

export const getters: GetterTree<RootState, RootState> = {
  [GETTERS.APP_INITIALIZED]: (state: RootState) => state.initialized,
  [GETTERS.LOADING]: (state: RootState) => state.loading,
  [GETTERS.CAN_ACCESS_APPLICATION_ACCOUNTS]: (state: RootState) => state.userInfo.applicationAccountsAccess,
  [GETTERS.USER_DISPLAY_NAME]: (state: RootState) => state.userInfo.displayName,
  [GETTERS.USER_ORGANISATIONS_NAMES]: (state: RootState) => state.userInfo.organisationsNames,
  [GETTERS.USER_ORGANISATION_IS_MASTER]: (state: RootState) => state.userInfo.isMasterOrganisation,
  [GETTERS.USER_CAN_MARK_PAYMENT_AS_EXECUTED]: (state: RootState) => state.userInfo.canMarkPaymentAsExecuted,
  [GETTERS.USER_HAVE_ACCESS_TO_AUDIT_AREA]: (state: RootState) => state.userInfo.haveAccessToAuditArea,
  [GETTERS.USER_CAN_MASS_DELETE_SETTLEMENTS]: (state: RootState) => state.userInfo.massDeleteSettlements,
  [GETTERS.USER_CAN_SEND_SETTLEMENTS_EMAILS_TO_DRIVERS]: (state: RootState) => state.userInfo.sendSettlementEmailsToDrivers,
  [GETTERS.USER_CAN_DELETE_DRIVERS]: (state: RootState) => state.userInfo.canDeleteDrivers,
  [GETTERS.USER_CAN_EXPORT_SETTLEMENTS]: (state: RootState) => state.userInfo.canExportSettlements,
  [GETTERS.USER_CAN_IMPORT_SETTLEMENTS]: (state: RootState) => state.userInfo.canImportSettlements,
  [GETTERS.USER_ROLE]: (state: RootState) => state.userInfo.role,
  [GETTERS.USER_ID]: (state: RootState) => state.userInfo.id,
  [GETTERS.ARTICLE_GROUPS]: (state: RootState) => state.articleGroups,
  [GETTERS.USER_CAN_MODIFY_PETROL_GROSS]: (state: RootState) => state.userInfo.canModifyPetrolGross,
  [GETTERS.USER_CAN_MODIFY_RAVAPI_BONUS]: (state: RootState) => state.userInfo.canModifyRavapiBonus,
  [GETTERS.USER_CAN_MODIFY_SETTLEMENT_APPLICATION]: (state: RootState) => state.userInfo.canModifySettlementApplication,
  [GETTERS.USER_CAN_APPROVE_SETTLEMENTS]: (state: RootState) => state.userInfo.canApproveSettlements,
  [GETTERS.USER_CAN_MOVE_SETTLEMENTS_BACK_TO_WAITING]: (state: RootState) => state.userInfo.canMoveSettlementBackToWaiting,
  [GETTERS.USER_STATISTIC_BUNDLE]: (state: RootState) => state.userInfo.statisticBundleId,
  [GETTERS.USER_CAN_DELETE_PAYOFF]: (state: RootState) => state.userInfo.canDeletePayoff,
  [GETTERS.USER_CAN_CANCEL_PAYOFF]: (state: RootState) => state.userInfo.canCancelPayoff,
  [GETTERS.USER_CAN_EXPORT_REQUEST_FOR_PAYMENT]: (state: RootState) => state.userInfo.canExportRequestForPayment,
  [GETTERS.USER_HAVE_ACCESS_TO_REPORT_AREA]: (state: RootState) => state.userInfo.haveAccessToReportArea,
  [GETTERS.CONFIRM_IS_OPEN]: (state: RootState) => state.confirm.isOpen,
  [GETTERS.VEHICLE_LOG_VISITED]: (state: RootState) => state.visitedHints.vehicleLog,
  [GETTERS.USER_CAN_MASS_SET_VEHICLES_COMMISSION]: (state: RootState) => state.userInfo.canMassSetVehiclesCommission,
  [GETTERS.USER_CAN_APPROVE_COLLIDING_SETTLEMENTS]: (state: RootState) => state.userInfo.canApproveCollidingSettlements,
  [GETTERS.USER_CAN_MASS_ADJUST_SETTLEMENT_COMMISSIONS]: (state: RootState) => state.userInfo.canMassAdjustSettlementCommissions,
  [GETTERS.USER_CAN_HOLDOFF_SETTLEMENT_PAYOFF]: (state: RootState) => state.userInfo.canHoldOffSettlementPayoff,
  [GETTERS.USER_CAN_EXPORT_EQUIPMENT_RETURN_REQUEST]: (state: RootState) => state.userInfo.canExportEquipmentReturnRequest,
  [GETTERS.USER_CAN_EXPORT_PROOF_OF_INCOME]: (state: RootState) => state.userInfo.canExportToPdfProofOfIncome,
  [GETTERS.USER_CAN_REMOVE_DRIVER_CONTACT_FORM]: (state: RootState) => state.userInfo.canRemoveDriverContactForm,
  [GETTERS.USER_CAN_EXPORT_PAYMENTS_SUMMARY_FOR_TAX]: (state: RootState) => state.userInfo.canExportPaymentsSummaryForTax,
  [GETTERS.USER_CAN_CHANGE_STATE_EXCLUDE_FROM_EXPORT_SETTLEMENT]: (state: RootState) => state.userInfo.canChangeStateExcludeFromExportSettlement,
  [GETTERS.USER_CAN_CREATE_INVOICE]: (state: RootState) => state.userInfo.canCreateInvoice,
  [GETTERS.USER_CAN_MANUAL_EDIT_DRIVER_SUBJECT]: (state: RootState) => state.userInfo.canManualEditDriverSubject,
  [GETTERS.USER_CAN_CHANGE_VEHICLE_IS_INCLUDED_IN_DAYOFF]: (state: RootState) => state.userInfo.canChangeVehicleIsIncludedInDayOff,
  [GETTERS.USER_CAN_CHANGE_VEHICLE_DISCOUNTED_COMMISSIONS]: (state: RootState) => state.userInfo.canChangeVehicleDiscountedCommissions,
  [GETTERS.USER_IS_EXCLUDED_FROM_VEHICLE_COMMISSIONS_REMINDER]: (state: RootState) => state.userInfo.isExcludedFromVehicleCommissionsReminder,
  [GETTERS.USER_CAN_CHANGE_VEHICLE_COMMISSIONS]: (state: RootState) => state.userInfo.canChangeVehicleCommissions,
  [GETTERS.USER_CAN_MANAGE_VEHICLE_DOCUMENTS]: (state: RootState) => state.userInfo.canManageVehicleDocuments,
  [GETTERS.USER_CAN_IMPORT_PAYOFFS]: (state: RootState) => state.userInfo.canImportPayoffs,
  [GETTERS.USER_CAN_ASSIGN_DIGITAL_PROMISSORY_NOTE_IN_RECEIVING_VEHICLE]: (state: RootState) => state.userInfo.canAssignDigitalPromissoryNoteInReceivingVehicle,
  [GETTERS.USER_HAVE_ACCESS_TO_DIGITAL_DOCUMENTS_MANAGE_AREA]: (state: RootState) => state.userInfo.haveAccessToDigitalDocumentsManageArea,
  [GETTERS.USER_CAN_CONFIRM_DRIVER_DOCUMENTS_WITHOUT_UPLOADING_FILE]: (state: RootState) => state.userInfo.canConfirmDriverDocumentsWithoutUploadingFile,
  [GETTERS.USER_CAN_MANAGE_BONUS_CAMPAIGNS]: (state: RootState) => state.userInfo.canManageBonusCampaigns,
  [GETTERS.USER_CAN_REMOVE_BLOCKING_WITHDRAW_DRIVER_STATUS_TAGS]: (state: RootState) => state.userInfo.canRemoveBlockingWithdrawDriverStatusTags,
  [GETTERS.USER_CAN_SKIP_MISSING_VEHICLE_RENT_DIGITAL_DOCUMENTS_POPUP_REMINDER]: (state: RootState) => state.userInfo.skipMissingVehicleRentDigitalDocumentsPopupReminder,
  [GETTERS.USER_CAN_VIEW_MISSING_DRIVERS_DIGITAL_DOCUMENTS]: (state: RootState) => state.userInfo.canViewMissingDriversDigitalDocuments,
  [GETTERS.USER_CAN_CHANGE_STUDENT_VALIDATION_STATUS]: (state: RootState) => state.userInfo.canChangeStudentValidationStatus,
  [GETTERS.USER_HAVE_ACCESS_TO_MAIL_AREA]: (state: RootState) => state.userInfo.haveAccessToMailArea,
  [GETTERS.USER_CAN_ADD_OR_REMOVE_DRIVER_DAYOFF]: (state: RootState) => state.userInfo.canAddOrRemoveDriverDayoff,
  [GETTERS.USER_CAN_MANAGE_MAIL_RULES]: (state: RootState) => state.userInfo.canManageMailRules,
};
