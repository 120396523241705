/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import axios, { AxiosInstance } from 'axios';
import router from '@/router';
import { TokenService } from '@/services/token.service';
import jwtDecode from 'jwt-decode';
import store from '@/store';
import { ACTIONS, MUTATIONS } from '@/store/namespaces'
import moment from 'moment'
import { AuthService } from './auth.service';
import { NotificationTypeEnum } from '@/enums/notification-type.enum'
import i18n from '@/i18n/config';
import { LF } from '@/i18n/LF'
import * as Sentry from "@sentry/vue";

const qs = require('qs');

export default abstract class ApiClientBase {
  protected instance: AxiosInstance;

  protected baseUrl: string;

  static pendingRefreshToken: Promise<void>;

  constructor() {
    this.instance = axios.create();
    if(process.env.NODE_ENV === 'development') {
      this.instance.defaults.baseURL = `${process.env.VUE_APP_EXPRESS_URL}/api`;
    } else {
      this.instance.defaults.baseURL = `/api`;
    }
    this.instance.defaults.withCredentials = true;
    this.baseUrl = '';
    const language = localStorage.getItem('language')

    // Required to work with NSwag auto generated
    this.instance.defaults.transformResponse = [];

    this.instance.interceptors.request.use((request) => {
      request.headers.L = language;

      return request;
    })

    this.instance.interceptors.response.use((response) => response,
      (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          if (error.config.url.includes('refreshtoken')) {
            Sentry.captureException(error);
            router.replace('/login');

            return Promise.reject(error)
          }

          if (error.config.url.includes('isloggedin') || error.config.url.includes('login'))
            Sentry.captureException(error);

          originalRequest._retry = true;

          if (ApiClientBase.pendingRefreshToken)
            return ApiClientBase.pendingRefreshToken
            .then(async () => await this.instance.request(originalRequest))
            .catch((error) => {
              Sentry.captureException(error);
              return Promise.reject('unable to refresh token');
            });

          const response = this.instance.post('/refreshtoken')
            .finally(() => {
              ApiClientBase.pendingRefreshToken = undefined;
            });

          if (!ApiClientBase.pendingRefreshToken)
            ApiClientBase.pendingRefreshToken = response;

          return response;

        } else if (error.response.status === 403) {
          store.commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: [i18n.t(LF.NoAccess)] });
        } else if (error.response.status === 408) {
          store.commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: [i18n.t(LF.TimeLimitExceeded)] });
        } else if (error.response.status === 413) {
          store.commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: [i18n.t(LF.FileCannotBeBiggerThan50MB)]});
        } else {
          store.commit(MUTATIONS.SHOW_SNACKBAR_MESSAGE, { type: NotificationTypeEnum.Error, content: [i18n.t(LF.SomethingWentWrong)] });
        }

        return Promise.reject(error);
      });
  }
}
